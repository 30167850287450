import { computed, reactive } from 'vue';
import { APIClientInterface } from '@/api/APIClientInterface';

export type User = {
  id: number | null;
  email: string;
  companyName: string;
  isPersonal: boolean;
  lastName: string;
  firstName: string;
  phoneNumber: string;
  industry: string;
  language: string;
  refNumPrefix: string;
  address: Address;
  attorneyOffice?: AttorneyOffice;
  channeltalkCustomerId: string;
};

export type Address = {
  country: string;
  zipcode: string;
  prefecture: string;
  city: string;
  street: string;
};

export type AttorneyOffice = {
  id: number | null;
  isCorporation: boolean;
  stripeAccountId: string;
  termsURL: string;
  name: {
    ja: string;
    en: string;
    zh: string;
  };
  representative: {
    ja: string;
    en: string;
    zh: string;
  };
};

export const useUserData = (apiClient: APIClientInterface) => {
  const state = reactive<User>({
    id: null,
    email: '',
    companyName: '',
    isPersonal: false,
    lastName: '',
    firstName: '',
    phoneNumber: '',
    industry: '',
    language: '',
    refNumPrefix: '',
    address: {
      country: 'JP',
      zipcode: '',
      prefecture: '',
      city: '',
      street: '',
    },
    channeltalkCustomerId: '',
  });

  const authenticated = computed(() => state.id !== null);

  const isAttorneyCorporation = computed(() => {
    // 源泉徴収対象の料金にならないようデフォルトはtrueにしておく
    if (!authenticated.value || !state.attorneyOffice) {
      return true;
    }

    return state.attorneyOffice.isCorporation;
  });

  async function loadUser () {
    const { data, error } = await apiClient.getUser();
    if (error) {
      return error;
    }

    if (data) {
      Object.assign(state, data);

      if (!state.address.country) {
        state.address.country = 'JP';
      }

      // 弁理士事務所割り当て済であれば読み込む
      if (data.attorney_office_id) {
        await assignAttorneyOffice();
      }
    }
  }

  async function updateUser () {
    const {
      isPersonal,
      companyName,
      firstName,
      lastName,
      phoneNumber,
      address,
      channeltalkCustomerId,
    } = state;
    const { error } = await apiClient.updateUser({
      ...(companyName !== undefined && { companyName }),
      isPersonal,
      firstName,
      lastName,
      phoneNumber,
      address,
      channeltalkCustomerId,
    });

    return error;
  }

  async function lookupAddress () {
    const { prefecture, city, street } = await apiClient.lookupAddress(
      state.address.zipcode,
    );

    state.address.prefecture = prefecture;
    state.address.city = city;
    state.address.street = street;
  }

  async function assignAttorneyOffice () {
    const { data } = await apiClient.assignAttorneyOffice();
    if (data) {
      state.attorneyOffice = data;
    }
  }

  return {
    state,
    authenticated,
    isAttorneyCorporation,
    loadUser,
    updateUser,
    lookupAddress,
    assignAttorneyOffice,
  };
};

export type UserDataStore = ReturnType<typeof useUserData>;
