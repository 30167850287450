<template>
  <div class="bg-bgGray flex justify-center">
    <div className="bg-white w-[368px] md:w-[742px]  my-16 p-8 rounded-lg shadow-lg">
      <h1 className="font-bold text-[24px] md:text-[26px] text-[#14181ACC] text-center pb-8 tracking-[2.88px]">
        {{ t('header') }} <!-- ご登録ありがとうございます -->
      </h1>
      <div className="text-center leading-normal tracking-[1.92px] mb-4 text-[14px] md:text-base">
        {{ t('desc01') }}<br>
        {{ t('desc02') }}
      </div>
      <!-- 類似商標を検索する -->
      <div className="flex justify-center mb-8">
        <PrimaryButton
          :title="t('search_button')"
          class="w-[300px] h-[48px] tracking-[1.68px]"
          @click="goToSearch"
        />
      </div>
      <div className="text-center leading-normal tracking-[1.92px] mb-4 text-[14px] md:text-base">
        {{ t('desc10') }}
      </div>
      <!-- 無料商標相談を予約する -->
      <div className="flex justify-center">
        <SecondaryButton
          class="font-normal text-grayAlpha60 w-[300px] h-[48px] tracking-[1.68px]"
          :title="t('consult_button')"
          @click="goToTimeRex"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { ja } from '@/i18n/pages/app/users/sign_up/thanks/ja';
import { en } from '@/i18n/pages/app/users/sign_up/thanks/en';
import { zh } from '@/i18n/pages/app/users/sign_up/thanks/zh';
import { useRouter } from 'vue-router';
const router = useRouter();
const { t } = useI18n({ messages: ja, en, zh });

const goToSearch = () => {
  router.push('/app/search');
};

const goToTimeRex = () => {
  const url = import.meta.env.VITE_TIME_REX_URL1;
  window.open(url, '_blank', 'noopener,noreferrer');
};
</script>
