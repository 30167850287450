export const en = {
  en: {
    header: 'Membership Registration Completed',
    desc01: 'Trademark registration begins with checking whether the trademark you are considering can be registered.',
    desc02: 'Let’s search for similar trademarks by entering your desired name or image!',
    search_button: 'Search for Similar Trademarks',
    desc10: 'If you would like to proceed with the process while receiving support from a trademark coordinator, click here.',
    consult_button: 'Book a Free Trademark Consultation',
  },
};
