export const blankToHyphen = (str: string): string => {
  if (str === '') {
    return '-';
  }
  return str;
};

// 携帯電話番号(070|080|090)の場合は引数ママ返却。以外は null
export const mobileNumber = (phoneNumber: string): string | null => {
  const mobilePattern = /^(070|080|090)\d+$/;
  return mobilePattern.test(phoneNumber) ? phoneNumber : null;
};

// 固定電話(070|080|090以外)の場合は引数ママ返却。以外は null
export const landlineNumber = (phoneNumber: string): string | null => {
  const pattern = /^(?!070|080|090)\d+$/;
  return pattern.test(phoneNumber) ? phoneNumber : null;
};
