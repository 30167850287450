export const zh = {
  zh: {
    header: '会员注册已完成',
    desc01: '商标注册从确认您正在考虑的商标是否可以注册开始。',
    desc02: '立即搜索您正在考虑的名称或图片，看看是否有相似的商标吧！',
    search_button: '搜索相似商标',
    desc10: '如果您希望在商标协调员的支持下进行注册手续，请点击此处。',
    consult_button: '预约免费商标咨询',
  },
};
