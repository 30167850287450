export const ja = {
  ja: {
    header: '会員登録が完了しました',
    desc01: '商標登録は、検討中の商標が登録できそうかを確認するところからはじまります。',
    desc02: 'さっそく、ご検討中のネーミングや画像を検索してみましょう！',
    search_button: '類似商標を検索する',
    desc10: '商標コーディネーターのサポートを受けながら手続きを進めたい方はこちら',
    consult_button: '無料商標相談を予約する',
  },
};
