<template>
  <Head>
    <title>
      {{ t('title') }}
    </title>
    <link
      rel="stylesheet"
      href="https://fonts.googleapis.com/icon?family=Material+Icons"
    >
    <link
      rel="stylesheet"
      href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@48,400,0,0"
    >
  </Head>
  <div class="cursor-default">
    <template v-if="!usesSignUpPageContainer && !enterprisePageContainer">
      <TheHeaderMobile v-if="$device.isMobile" />
      <TheHeaderDesktop v-else />
    </template>
    <SignUpPageContainer v-if="usesSignUpPageContainer" />
    <TrademarkFormPageContainer v-else-if="usesSearchPageContainer" />
    <router-view v-else />
    <TheFooter v-if="!enterprisePageContainer" />
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, provide } from 'vue';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';
// コンポーネント
import SignUpPageContainer from '@/components/pages/app/users/sign_up/index.vue';
import TrademarkFormPageContainer from '@/components/search/TrademarkFormPageContainer.vue';
import TheHeaderDesktop from '@/components/header/TheHeaderDesktop.vue';
import TheHeaderMobile from './components/header/TheHeaderMobile.vue';
import TheFooter from '@/components/footer/default.vue';
// 状態管理
import { APIClientKey, UserDataKey } from '@/composables/injectionKeys';
import { useUserData } from '@/composables/useUserStore';
// ライブラリ
import { Head, useHead } from '@vueuse/head';
import ChannelService from '@/lib/channelService';
import { TrademarkApplication } from './api/APIClientInterface';
import { APIClient } from './api/APIClient';
import { mobileNumber, landlineNumber } from '@/lib/util/stringUtils';

const apiClient = new APIClient();
provide(APIClientKey, apiClient);

const route = useRoute();

const usesSignUpPageContainer = computed(() => {
  const names = ['SignUpEmailPage', 'SignUpCodePage', 'SignUpFormPage'];
  return !!route.name && names.includes(route.name.toString());
});

const enterprisePageContainer = computed(() => {
  const names = ['EnterprisePlanPage'];
  return !!route.name && names.includes(route.name.toString());
});

const usesSearchPageContainer = computed(() => {
  const names = [
    'SearchPage',
    'ImageSearchPage',
    'RegistTrademarkPage',
    'RegistTrademarkContainerPage',
    'RegistTrademarkTrademarkPage',
    'RegistTrademarkApplicantPage',
    'RegistTrademarkCheckoutPage',
  ];
  return !!route.name && names.includes(route.name.toString());
});

useHead({
  script: [
    {
      async: true,
      src: 'https://static.criteo.net/js/ecp_solutions/crto_sha256.js',
    },
  ],
});

// アプリケーション全体で使う翻訳定義
const { t, locale } = useI18n({
  useScope: 'global',
  messages: {
    ja: {
      title:
        'オンライン商標登録サービスのCotobox（コトボックス）',
      common: {
        trademarkType: {
          text: '文字商標',
          image: 'ロゴ商標',
        },
        klass: '区分',
        goodsServices: '商品役務',
        klassNumber: '第{0}類',
        required: '必須',
        optional: '任意',
        update: '更新',
        asterisk: '※',
        link: 'こちら',
        notInputted: 'が入力されていません。',
        notSelected: 'が選択されていません。',
        next: '次へ進む',
        error: 'エラー',
        contactViaChat: 'お手数ですがチャットでご連絡ください。',
      },
    },
    en: {
      common: {
        trademarkType: {
          text: 'Text',
          image: 'Logo',
        },
        klass: 'Class',
        goodsServices: 'Goods and services',
        klassNumber: 'Class {0}',
        required: 'Required',
        optional: 'Optional',
        update: 'Update',
        asterisk: '*',
        link: 'here',
        notInputted: 'has not been inputted.',
        notSelected: 'has not been selected.',
        next: 'Next',
        error: 'Error',
        contactViaChat: 'Please contact us via chat.',
      },
    },
    zh: {
      common: {
        trademarkType: {
          text: '标准字符',
          image: '标志商标',
        },
        klass: '分类',
        goodsServices: '商品服务',
        klassNumber: '第{0}类',
        required: '必須',
        optional: '可选',
        update: '完成',
        asterisk: '*',
        link: '此处',
        notInputted: '没有被输入',
        notSelected: '没有被输入',
        next: '转到下一个',
        error: '误差',
        contactViaChat: '请通过聊天与我们联系',
      },
    },
  },
});

const userData = useUserData(apiClient);
provide(UserDataKey, userData);

const pluginKey = import.meta.env.VITE_CHANNEL_TALK_KEY;
const customLauncherSelector = '.open-channeltalk';
const hideChannelButtonOnBoot = false;
const mobileMessengerMode = 'newTab';
const channelTalkBootParams = {
  pluginKey,
  customLauncherSelector,
  hideChannelButtonOnBoot,
  mobileMessengerMode,
};

onMounted(async () => {
  addErrorEventListener();

  const authError = await userData.loadUser();
  if (authError) {
    // チャネルトーク起動
    ChannelService.boot(channelTalkBootParams, (error, user) => {
      if (error) {
        console.error(error);
      } else {
        userData.state.channeltalkCustomerId = user.id;
      }
    });

    // ブラウザの言語設定をvue-i18nに適用
    const browserLanguage = ((window.navigator.languages &&
      window.navigator.languages[0]) ||
      window.navigator.language ||
      // @ts-ignore
      window.navigator.userLanguage ||
      // @ts-ignore
      window.navigator.browserLanguage) as string;
    const language = ['ja', 'en', 'zh'].includes(
      browserLanguage.substring(0, 2),
    )
      ? browserLanguage.substring(0, 2)
      : 'en';
    locale.value = language;
    userData.state.language = language;
  } else {
    // ログイン済ユーザー向けの処理
    const {
      id: userId,
      email,
      firstName,
      lastName,
      phoneNumber,
      companyName,
      industry,
      attorneyOffice,
      language,
      refNumPrefix,
    } = userData.state;
    const authenticated = userData.authenticated.value;
    locale.value = language;
    const tmApps = await apiClient
      .fetchTrademarkApplications()
      .catch(() => [] as TrademarkApplication[]);
    const purchased = tmApps.length > 0;

    ChannelService.boot(
      {
        ...channelTalkBootParams,
        ...(authenticated && { memberId: String(userId) }),
        ...(authenticated && {
          profile: {
            name: `${lastName}${firstName}`,
            email,
            phoneNumber,
            mobileNumber: mobileNumber(phoneNumber),
            landlineNumber: landlineNumber(phoneNumber),
            companyName,
            language,
            industry,
            referenceNumPrefix: refNumPrefix,
            purchased,
            ...(attorneyOffice && {
              attorneyOfficeId: `${attorneyOffice.id}`,
              attorneyOfficeName: attorneyOffice.name[userData.state.language],
            }),
          },
        }),
      },
      (error, user) => {
        if (error) {
          console.error(error);
        } else {
          if (user.id !== userData.state.channeltalkCustomerId) {
            userData.state.channeltalkCustomerId = user.id;
            userData.updateUser();
          }
        }
      },
    );
    // ここはGA連携したらダメなのでは？
    if (window.dataLayer) {
      window.dataLayer.push({
        isLoggedIn: true,
        userId: String(userId),
        email,
        // @ts-ignore
        ...(window.crto_sha256 && {
          // @ts-ignore
          cto_hash_email: window.crto_sha256(email.toLowerCase()),
        }),
        firstName,
        lastName,
        phoneNumber,
        mobileNumber: mobileNumber(phoneNumber),
        landlineNumber: landlineNumber(phoneNumber),
        companyName,
        industry,
        language,
        refNumPrefix,
        purchased,
        ...(attorneyOffice && {
          attorneyOfficeId: `${attorneyOffice.id}`,
          attorneyOfficeName: attorneyOffice.name[userData.state.language],
        }),
      });
    }
  }
  window.dataLayer.push({ event: 'page_loaded' });
  window.dataLayer.push({ event: 'Page Loaded with User Data' });
});

function addErrorEventListener () {
  window.onunhandledrejection = (event: PromiseRejectionEvent) => {
    apiClient.notifyBrowserError({
      message: event.reason.message,
      stack: event.reason.stack,
      url: window.location.href,
    });
  };

  window.onerror = (message, source, lineno, colno, error) => {
    apiClient.notifyBrowserError({
      // @ts-ignore
      message,
      lineno,
      colno,
      filename: source,
      url: window.location.href,
      ...(error && { stack: error.stack }),
    });
  };
}
</script>

<style lang="scss">
@import './assets/css/reset.css';

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #222222;
  background-color: #fff;
}

body {
  background-color: #f4f4f4;
}

a {
  color: #222222;
  text-decoration: underline;
  cursor: pointer;
}

button {
  cursor: pointer;
}

.inner {
  @include pc {
    width: 1000px;
    margin: 0 auto;
  }
}

.mobileInner {
  @include sp {
    padding: 0 16px;
  }
}

.primaryColor {
  color: #e6713d;
}
</style>
